import React, { Component } from 'react';
import ProjectList from './components/Projects/ProjectList';
import guitarImage from './assets/images/GuitarLogo-1.png';
import convolutionImage from './assets/images/ConvolutionLogo.png';
import './components/Projects/Project.css'
import './App.css';

const ProjectsL = [
  {
    title: 'Guitar',
    image: guitarImage,
    description: 'Videos of me playing guitar',
  },
  {
    title: 'Convolution App',
    image: convolutionImage,
    description: 'Digital Convolution Plugin for any two audio files',
  }
];

class App extends Component {
  render() {
    return (
        <div className='myStyle'>
          <h1>EmreKarabay</h1>
          <div className='projectListContainer'>
            <ProjectList className='project-list'
              items={ProjectsL} 
            />
          </div>
        </div>
    );
  }
}

export default App;
