import React from 'react';
import './Project.css';


function Project(props) {
  return (
    <div className="project-container">
      <img className="project-image" src={props.image} alt={props.title} />
      <h2 className="project-title">{props.title}</h2>
      <p className="project-description">{props.description}</p>
    </div>
  );
}

export default Project;
