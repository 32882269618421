import React from 'react';
import Project from './Project';
import Card from '../UI/Card';
import './Project.css';

function ProjectList(props) {
  console.log(props.items);  // Check if props.items is what you expect

  return (
    <div>
      {props.items && props.items.length === 0 ? (
        <p>PlaceHolder</p>
      ) : (
        props.items && props.items.map((project, index) => (
          <Project
            key={index}
            image={project.image}
            title={project.title}
            description={project.description}
          />
        ))
      )}
    </div>
  );
}

export default ProjectList;
